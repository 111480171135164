import Accordion from "components/dist/atoms/Accordion"
import Button from "components/dist/atoms/Button"
import Icon from "components/dist/atoms/Icon"
import Text from "components/dist/atoms/Text"
import FileIcon from "components/dist/molecules/FileIcon"
import { useEffect, useState } from "react"

type UploadStatus = 'uploading' | 'success' | 'error'

export interface ToastFile {
    status: UploadStatus,
    file: File,
    uniqueId: string,
    abortController: AbortController
}

interface UploadingToastProps {
    status: UploadStatus,
    files: ToastFile[],
    onCancelAll: (files: ToastFile[]) => void
}

export const UploadingToast = (props: UploadingToastProps) => {
    const [files, setFiles] = useState<ToastFile[]>([])

    useEffect(() => {
        // append new files to the list
        // and update existing files
        const newFiles = props.files.filter(file => !files.some(f => f.uniqueId === file.uniqueId))
        const updatedFiles = files.map(file => {
            const newFile = props.files.find(f => f.uniqueId === file.uniqueId)
            if (newFile) {
                return newFile
            }
            return file
        })
        setFiles([
            ...newFiles,
            ...updatedFiles
        ])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.files])

    const uploadingFilesCount = files.filter(file => file.status === 'uploading').length
    const uploadedFilesCount = files.filter(file => file.status === 'success').length
    return <div
        role="alert"
        className="flex flex-col gap-2">
        <Accordion
            type="single"
            collapsible
            defaultValue="uploading">
            <Accordion.Item value="uploading">
                <div className="flex gap-2 w-full items-center">
                    <Accordion.Trigger
                        asChild
                        onClick={(e) => e.stopPropagation()}>
                        <div className="flex gap-2 flex-1 justify-start">
                            {props.status === 'uploading' && <div className='w-5 aspect-square border-2 border-t-transparent animate-spin border-green-flowkit rounded-full' />}
                            {props.status === 'success' && <Icon name="Check" width={20} height={20} className="bg-green-flowkit text-white rounded-full" />}
                            <Text size="sm">
                                {props.status === 'uploading' && `Uploading ${uploadingFilesCount} items to my shoebox`}
                                {props.status === 'success' && `${uploadedFilesCount} ${uploadedFilesCount === 1 ? "Upload" : "Uploads"} Complete`}
                            </Text>
                        </div>
                    </Accordion.Trigger>
                    <Button
                        size="custom"
                        variant="ghost"
                        className="items-center flex rounded-md text-black-dark"
                    >
                        <Icon strokeWidth={1.5} name="Cancel" width={20} height={20} />
                    </Button>
                </div>

                <Accordion.Content onClick={(e) => e.stopPropagation()}>
                    <div className="flex flex-col gap-3">
                        {files.map((file, fileIndex) => {
                            return <div
                                className="flex gap-2 items-center h-8"
                                key={`${file.file.lastModified}-${file.file.name}-${file.file.size}-${fileIndex}`}>
                                <FileIcon
                                    className="shrink-0"
                                    fileName={file.file.name} />
                                <Text
                                    size="sm"
                                    as="div" truncate>
                                    {file.file.name}
                                </Text>
                                {file.status === 'uploading' && <Button
                                    size="custom"
                                    variant="ghost"
                                    className="h-8 rounded-md text-black-dark shrink-0"
                                    onClick={() => {
                                        file.abortController.abort("single")
                                        setFiles(prevState => prevState.filter((f, previousIndex) => previousIndex !== fileIndex))
                                    }}>
                                    <Icon strokeWidth={1.5} name="Cancel" width={20} height={20} />
                                </Button>}
                            </div>
                        })}
                    </div>
                </Accordion.Content>
            </Accordion.Item>

        </Accordion>
        {props.status === 'uploading' && <div>
            <Button
                aria-label="Cancel All"
                onClick={() => {
                    props.onCancelAll(files.filter(file => file.status === 'uploading'))
                }}
                className="bg-white"
                variant="outline" size="sm">
                Cancel
            </Button>
        </div>}
    </div>
}