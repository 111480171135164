import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { ANONYMOUS_VIEW } from 'src/constants/person';
import { MAIN_CONTENT_FOOTER_ELEMENT_ID, Route } from 'src/constants/ui';
import { FormElementProvider } from 'src/contexts/form-element-context';
import { MessagesContextProvider } from 'src/contexts/messages-context';
import { UploadFormElementContextProvider } from 'src/contexts/upload-form-element-context';
import { useActiveLoan } from 'src/hooks/use-active-loan';
import { useDragOver } from 'src/hooks/use-drag-over';
import { useLicenseKeys } from 'src/hooks/use-license-keys';
import { useMounted } from 'src/hooks/use-mounted';
import { useOpenDrawer } from 'src/hooks/use-open-drawer';
import { useUser } from 'src/hooks/use-user';
import { useQueryParamToast } from 'src/hooks/useQueryParamToast';
import { DndProvider } from 'src/providers/dnd-provider';
import { fetch3rdPartyLicenseKeys } from 'src/slices/license-keys';
import { getJavascriptConstants } from 'src/slices/lists';
import { selectConnectionError, selectDrawerOpen, toggleDrawer } from 'src/slices/ui';
import { useDispatch, useSelector } from 'src/store';

import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import { ShoeBoxItemProvider } from './shoebox-item-viewer/shoebox-item-viewer.context';
import { ShoeBoxItemViewerActions } from './shoebox-item-viewer/shoebox-item-viewer-actions';

const ShoeBoxItemViewer = dynamic(() => import('src/components/dashboard/shoebox-item-viewer/shoebox-item-viewer.component').then(module => module.ShoeBoxItemViewer), { ssr: false });
const Error500 = dynamic(() => import('src/icons/error500_light').then(module => module.Error500), { ssr: false });
const DowntimeAlert = dynamic(() => import('../maintenance/downtime-alert').then(module => module.DowntimeAlert), { ssr: false });
const TimezoneChecker = dynamic(() => import('../user/timezone-checker').then(module => module.TimezoneChecker), { ssr: false });
interface DashboardLayoutProps {
  children?: ReactNode;
  noSideBar?: boolean;
  showGoBackButton?: boolean;
  noSearch?: boolean;
}

const DashboardLayoutRoot = styled('div')(
  ({ theme }) => ({
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flex: '1 1 auto',
    backgroundColor: theme.palette.neutral[200],
    paddingTop: 54
  })
);

export const DashboardLayout: FC<DashboardLayoutProps> = (props) => {
  const { children } = props;
  useQueryParamToast();
  useDragOver();
  useOpenDrawer();
  const { loan } = useActiveLoan();
  // hooks
  const dispatch = useDispatch();
  const isMounted = useMounted();
  const { pdftronKey } = useLicenseKeys();
  const { asPath, pathname, push, isReady, query } = useRouter();
  const theme = useTheme();
  // redux state selectors
  const drawerOpen = useSelector(selectDrawerOpen);
  const connectionError = useSelector(selectConnectionError);
  const { viewType } = useUser();
  const handleDrawerToggle = () => {
    dispatch(toggleDrawer(!drawerOpen));
  }

  useEffect(() => {
    if (isMounted() && viewType === ANONYMOUS_VIEW && isReady) {
      push({ pathname: Route.ANONYMOUS_HOME, query });
    }
  }, [viewType, isMounted, push, isReady, query]);

  useEffect(() => {
    if (isMounted() && isReady) {
      if (!([
        Route.HOME,
        Route.LENDER_HOME,
        Route.PRINCIPAL_HOME
      ] as string[]).includes(asPath.split('?')?.[0]) && pathname === Route.HOME) {
        push(asPath);
      }
    }
  }, [isReady, asPath, push, isMounted, pathname]);

  useEffect(() => {
    dispatch(getJavascriptConstants());
    // get 3rd party license keys
    dispatch(fetch3rdPartyLicenseKeys());
  }, [dispatch, viewType]);

  return (
    <UploadFormElementContextProvider>
      <MessagesContextProvider>
        <FormElementProvider loan={loan}>
          <DndProvider>
            <DashboardLayoutRoot>
              <Box
                className='bg-black-10 flex w-full flex-1 flex-col'
                sx={{
                  paddingLeft: {
                    lg: props.noSideBar ? '0px' : '58px',
                  },
                  transition: theme.transitions.create('padding', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen
                  }),
                  ...(drawerOpen && {
                    paddingLeft: {
                      lg: props.noSideBar ? '0px' : '256px',
                    },
                    transition: theme.transitions.create('padding', {
                      easing: theme.transitions.easing.easeOut,
                      duration: theme.transitions.duration.enteringScreen
                    })
                  }),
                }}
              >
                <DowntimeAlert />
                {children}
                <div id={MAIN_CONTENT_FOOTER_ELEMENT_ID} />
              </Box>
            </DashboardLayoutRoot>
            <TimezoneChecker />
            {!!connectionError && <Dialog
              open
              fullWidth
              maxWidth="sm">
              <div
                className='flex flex-col p-10 bg-black-20'
              >
                <Typography variant="h4" color='white'>
                  <CircularProgress size={25} />  Connection Error
                </Typography>
                <Typography variant="body1" color='white'>
                  Sorry we&apos;re having trouble connecting with you. Check that your Internet is working and seems stable. We&apos;ll keep trying here, but if you continue having issues, check back in a bit.
                </Typography>
                <Box sx={{
                  mt: 5,
                  display: 'inline-block',
                  position: 'relative',
                  width: '100%',
                  paddingBottom: '90%',
                  verticalAlign: 'middle',
                  overflow: 'hidden',
                }}>
                  <Error500
                    style={{
                      display: 'inline-block',
                      position: 'absolute',
                      top: 0,
                      left: 0
                    }} />
                </Box>
              </div>
            </Dialog>}
            <DashboardNavbar
              sidebarOpen={drawerOpen}
              noSideBar={props.noSideBar}
              noSearch={props.noSearch}
              onToggle={handleDrawerToggle}
              showGoBackButton={props.showGoBackButton} />
            {!props.noSideBar && <DashboardSidebar
              onToggle={handleDrawerToggle}
              open={drawerOpen}
            />}
            <ShoeBoxItemProvider>
              <ShoeBoxItemViewer pdftronKey={pdftronKey}>
                <ShoeBoxItemViewerActions />
              </ShoeBoxItemViewer>
            </ShoeBoxItemProvider>
          </DndProvider>
        </FormElementProvider>
      </MessagesContextProvider>
    </UploadFormElementContextProvider>);
};
