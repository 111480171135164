import { StorageType } from "src/backend"

interface FormElementStorageTypeGuardProps {
    requiredType: "FILE" | "FOLDER";
    type: "FILE" | "FOLDER";
}
export const FormElementStorageTypeGuard = (props: React.PropsWithChildren<FormElementStorageTypeGuardProps>) => {
    if (props.type === props.requiredType) {
        return <>{props.children}</>
    }
    return null;
}