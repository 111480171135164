import Stack from 'components/dist/atoms/Stack';
import { FormElementFileRowActions } from "src/components/v2/form-elements/form-element-row/form-element-row.actions.component"
import { useFormElementFileRow } from "src/components/v2/form-elements/form-element-row/form-element-row.hook"
import { useUploadFormElementContext } from 'src/contexts/upload-form-element-context/upload-form-element-context';
import { useUser } from 'src/hooks/use-user';

import { useShoeboxItemViewer } from "../shoebox-item-viewer.state";


export const ShoeBoxItemViewerElementActions = () => {
    const viewerState = useShoeboxItemViewer();
    const uploadFormElementContext = useUploadFormElementContext();
    const userState = useUser();

    const state = useFormElementFileRow({
        loan: viewerState.loan,
        formElement: viewerState.element,
        isNeedsListModal: true,
        loanViewType: viewerState.loanViewType,
        areMultipleElementsChecked: false,
        isSmallScreen: false,
        isMultiChecked: false,
        isUserAllowedToUpdateStatus: false,
        onCopyLink: (id) => uploadFormElementContext.onCopyElementLink(id),
        loggedInUserId: userState.user.id,
        loggedInUserRole: userState.user.loggedCompanyRole,
        isLoggedInUserALender: userState.isLender,
        onSendMessage: viewerState.onSendMessage,
    });

    return <Stack
        className='h-6 group is-actions-visible'
        items="center" justify="center">
        <FormElementFileRowActions
            {...state}
            onCopyClick={state.onCopyToClick}
            areMultipleElementsChecked={false}
            onSendMessage={viewerState.onSendMessage}
            onDropdownTriggerClick={state.onDropdownTriggerClick}
            isDropdownVisible={state.isDropdownVisible}
            onChangeDropDownVisibility={state.onChangeDropDownVisibility}
            isNeedsListModal
            isActive
            packageColumnStatus="FULL_WIDTH"
            dropdownPosition={state.dropdownClickPosition}
            formElement={viewerState.element}
            loanRoles={viewerState.loan.loanRoles}
            isUserAllowedToUpdateStatus={false}
        />
    </Stack>
}
