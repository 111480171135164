import { LoanDto, PackageInfoSharingResponseDto } from "src/backend";
import { TeamMemberSelectListAssignedListItem } from "src/components/v2/team-member-select-list/team-member-select-list.types";
import { useUser } from "src/hooks/use-user";
import { getElementSharedInfoTeams } from "src/utils/form-element/get-element-shared-info-teams";
import { mapSharedInfoToAssignListItem } from "src/utils/form-element/map-shared-info-to-assign-list-item";

interface SharedWithTeamsProps {
    sharedInfo: PackageInfoSharingResponseDto[];
    loanRoles: LoanDto['loanRoles'];
}

export const useSharedWithTeams = (props: SharedWithTeamsProps) => {
    const loggedInUserState = useUser();

    const assignedList: TeamMemberSelectListAssignedListItem[] = mapSharedInfoToAssignListItem({
        sharedInfo: props.sharedInfo,
        loanRoles: props.loanRoles,
        loggedInUserId: loggedInUserState.user.id
    });

    const teams = getElementSharedInfoTeams(assignedList);

    return {
        teams
    } as const;
};